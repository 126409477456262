import React, { useState } from "react";
import "../css/ThreeScreenDesign.css";

const formatData = {
  formats: [
    { title: "In-stream/out-stream", description: "In-stream and out-stream videos play within the context of video content, providing a seamless viewing experience." },
    { title: "In Banner Video", description: "In Banner Video allows video ads to be embedded within a banner, combining the reach of display with the engagement of video." },
    { title: "Rewarded Video", description: "Rewarded video is an ad format where users receive a reward for watching a video, which increases their engagement and satisfaction." },
    { title: "Нативная реклама", description: "Native advertising is a type of ad that matches the form and function of the platform on which it appears." },
    { title: "Push уведомления", description: "Push notifications are messages sent directly to a user's device, providing timely updates and promotions." },
    { title: "Статичный баннер", description: "Static banners are simple, non-animated ads that display a single image or message." },
    { title: "Динамический баннер", description: "Dynamic banners are animated ads that can display multiple messages or images in a single ad space." },
    { title: "Rich Media", description: "Rich Media ads include interactive elements such as video, audio, or other features that encourage user interaction." },
    { title: "Gammner", description: "Gammner ads are specialized ad formats designed for gaming environments." },
    { title: "AR-banner", description: "AR-banners use augmented reality to create immersive ad experiences." },
    { title: "Full screen", description: "Full screen ads take over the entire screen, providing an immersive ad experience." },
    { title: "Split screen", description: "Split screen ads divide the screen into two or more sections, each displaying a different ad." }
  ],
  ecosystem: [
    { title: "Продакшн команда", description: "Our production team offers comprehensive services for creating high-quality ad content." },
    { title: "15+ топовых SSP", description: "We integrate with over 15 top SSPs to ensure wide reach and high performance." },
    { title: "Собственная DSP", description: "Our proprietary DSP provides advanced targeting and optimization capabilities." },
    { title: "Обширная DMP", description: "Our extensive DMP allows for detailed audience segmentation and targeting." },
    { title: "Клиентский дэшборд", description: "Our client dashboard provides real-time insights and analytics for your ad campaigns." }
  ],
  tools: [
    { title: "Уникальные форматы", description: "We offer unique ad formats that stand out and capture attention." },
    { title: "Геймификация", description: "Gamification elements in ads increase engagement and user interaction." },
    { title: "Лендинг", description: "Landing pages are optimized for conversion, providing a seamless user experience." },
    { title: "Бренд-лифт", description: "Brand lift studies measure the impact of your ads on brand perception and awareness." },
    { title: "Динамическая оптимизация креативов", description: "Dynamic creative optimization tailors ad content to individual users for maximum relevance." },
    { title: "Динамический ретаргетинг", description: "Dynamic retargeting re-engages users who have previously interacted with your brand." },
    { title: "Geofence", description: "Geofencing targets users based on their physical location, providing highly relevant ads." },
    { title: "Smart Bidder", description: "Smart Bidder uses advanced algorithms to optimize your ad bids in real-time." },
    { title: "Предикторы", description: "Predictive models forecast ad performance, helping you make data-driven decisions." }
  ]
};

const descriptions = {
  formats: "Наша платформа поддерживает множество форматов рекламы, обеспечивая максимальную гибкость и креативные возможности для ваших кампаний. Мы предлагаем всё: от стандартных баннеров и видеообъявлений до интерактивных и нативных форматов.",
  ecosystem: "Наша эко-система предлагает полный набор инструментов и услуг для создания и управления вашими рекламными кампаниями, включая поддержку более 15 топовых SSP и собственную DSP.",
  tools: "Наши инструменты включают уникальные форматы, геймификацию, бренд-лифт и многие другие, предоставляя вам все необходимое для эффективных рекламных кампаний."
};

const titles = {
  formats: "Форматы",
  ecosystem: "Эко-система",
  tools: "Инструменты"
};

function PlatformFeatures() {
  const [selectedSection, setSelectedSection] = useState("ecosystem");
  const [expandedItem, setExpandedItem] = useState(null);

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    setExpandedItem(null); // Collapse all items when changing section
  };

  const handleItemClick = (index) => {
    setExpandedItem(expandedItem === index ? null : index);
  };

  return (
    <div className="FeatureWrapper">
      <div className="ContentContainer">
        <div className="FeatureGrid">
          <div className="FeatureColumn">
            <div className="FeatureInfo">
              <h2 className="FeatureTitle">Возможности платформы</h2>
              <p className="FeatureDescription">
                Мы стремимся обеспечить нашим клиентам максимальный результат от своих рекламных кампаний, используя передовые технологии и аналитические инструменты.
              </p>
            </div>
          </div>
          <div className="FeatureColumn">
            <div className="EcosystemInfo">
              <hr className="Divider" />
              <div 
                className={`EcosystemItem ${selectedSection === "ecosystem" ? "active" : ""}`} 
                onClick={() => handleSectionChange("ecosystem")}
              >
                Эко-система{selectedSection === "ecosystem" && <span className="Dot" />}
              </div>
              <div 
                className={`EcosystemItem ${selectedSection === "formats" ? "active" : ""}`} 
                onClick={() => handleSectionChange("formats")}
              >
                Разнообразие форматов{selectedSection === "formats" && <span className="Dot" />}
              </div>
              <div 
                className={`EcosystemItem ${selectedSection === "tools" ? "active" : ""}`} 
                onClick={() => handleSectionChange("tools")}
              >
                Инструменты{selectedSection === "tools" && <span className="Dot" />}
              </div>
              <hr className="Divider" />
            </div>
          </div>
          <div className="FeatureColumn">
            <div className="FormatInfo">
              <p className="FormatDescription">
                {descriptions[selectedSection]}
              </p>
              <h2 className="FormatTitle">{titles[selectedSection]}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="FormatSection">
        <hr className="Divider blue" />
        <div className="FormatList">
          <div className="FormatCounter">    </div>
          <div className="FormatItems">
            {formatData[selectedSection].map((item, index) => (
              <div key={index}>
                <div className="FormatItem" onClick={() => handleItemClick(index)}>
                  {item.title}
                  <span className="PlusIcon">{expandedItem === index ? "✕" : "+"}</span>
                </div>
                {expandedItem === index && (
                  <div className="ItemDescription">{item.description}</div>
                )}
                {index < formatData[selectedSection].length - 1 && <hr className="Divider blue" />}
              </div>
            ))}
          </div>
        </div>
        <button className="CallToAction">
          <span className="CTAText">Оставить заявку</span>
          <img className="CTAIcon" loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/612297f08d38dda93ed5fbe004791761ead0f43cade662deb342d697d77692e8?apiKey=2c0c9ac2862b4b0ba2180408ec812a23&" alt="" />
        </button>
      </div>
    </div>
  );
}

export default PlatformFeatures;
