import React, { useEffect, useRef } from "react";
import worldMap from '../assets/worldMap.png'; // Save the map image as world-map.png in the same directory
import '../css/TwoScreenDesign.css';

const StatCard = ({ number, description }) => (
  <div className="stat-card">
    <div className="stat-content">
      <div className="stat-number">{number}</div>
      <div className="stat-description">{description}</div>
    </div>
  </div>
);

function TwoScreenDesign({ scrollSpeed = 1.5, maxTranslateY = 700 }) {
  const stats = [
    { number: "126+", description: "локальных брендов" },
    { number: "155", description: "довольных клиентов" },
    { number: "7", description: "офисов в странах СНГ" },
  ];

  const imageRef = useRef(null);
  const descriptionRef = useRef(null);
  const containerRef = useRef(null);
  const startScrollTop = useRef(null);
  const lastScrollTop = useRef(0);
  const isScrollingUp = useRef(false);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.target === imageRef.current) {
          if (entry.intersectionRatio >= 0.1) {
            imageRef.current.style.transform = 'scale(1)';
          } else {
            imageRef.current.style.transform = 'scale(0.2)';
          }
        }

        if (entry.target === descriptionRef.current && entry.isIntersecting) {
          startScrollTop.current = window.scrollY || document.documentElement.scrollY;
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: [0, 0.1, 1]
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    if (descriptionRef.current) {
      observer.observe(descriptionRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
      if (descriptionRef.current) {
        observer.unobserve(descriptionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!descriptionRef.current || startScrollTop.current === null) return;

      const scrollTop = window.scrollY || document.documentElement.scrollY;
      const containerHeight = containerRef.current.offsetHeight;
      const descriptionHeight = descriptionRef.current.offsetHeight;
      const maxTranslation = Math.min(maxTranslateY, containerHeight - descriptionHeight);

      const viewportCenter = window.innerHeight / 2;
      const descriptionTop = descriptionRef.current.getBoundingClientRect().top;

      if (scrollTop > lastScrollTop.current) {
        // Scrolling down
        if (descriptionTop <= viewportCenter && scrollTop <= startScrollTop.current + maxTranslation) {
          const scrollDistance = (scrollTop - startScrollTop.current) * scrollSpeed;
          const translation = Math.max(0, Math.min(scrollDistance, maxTranslation));
          descriptionRef.current.style.transform = `translateY(${translation}px)`;
        }
        isScrollingUp.current = false;
      } else {
        // Scrolling up
        if (isScrollingUp.current || descriptionTop <= viewportCenter) {
          const scrollDistance = (scrollTop - startScrollTop.current) * scrollSpeed;
          const translation = Math.max(0, Math.min(scrollDistance, maxTranslation));
          descriptionRef.current.style.transform = `translateY(${translation}px)`;
        }
        isScrollingUp.current = true;
      }

      lastScrollTop.current = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollSpeed, maxTranslateY]);

  return (
    <section className="company-overview" ref={containerRef}>
      <div className="content-wrapper">
        <header className="company-header">
          <h2 className="company-title">О компании</h2>
          <p className="company-slogan">
            Точные таргетинги. <br />
            Уникальные креативы. <br />
            Эффективная оптимизация.
          </p>
        </header>
        <div className="stats-container">
          {stats.map((stat, index) => (
            <StatCard key={index} number={stat.number} description={stat.description} />
          ))}
        </div>
        <div className="company-details">
          <article className="company-description" ref={descriptionRef}>
            <p>
              С самого начала нашего пути мы запускали и разрабатывали множество интересных рекламных кампаний для разных сегментов бизнеса и клиентов из разных стран. Hitarget предоставляет все необходимые услуги для высокоэффективных рекламных запусков.
            </p>
            <div className="learn-more">
              <span>Больше о нас</span>
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba3edf194ade8514aa7b3bda6e18ca069f64eb879545215cbc5fd47df65d691e?apiKey=2c0c9ac2862b4b0ba2180408ec812a23&" alt="" className="arrow-icon" />
            </div>
          </article>
          <div className="company-image">
            <img ref={imageRef} src={worldMap} alt="Company overview" className="overview-image" />
          </div>
        </div>
      </div>

    </section>
  );
}

export default TwoScreenDesign;
