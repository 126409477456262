import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import '../css/SevenScreenDesign.css';

const SevenScreenDesign = () => {
    const [hovered, setHovered] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.8,
    });

    useEffect(() => {
        if (inView) {
            setHovered(true);
        } else {
            setHovered(false);
        }
    }, [inView]);

    const springs = [
        useSpring({ width: hovered ? 595 : 97, height: 97 }),
        useSpring({ width: hovered ? 515 : 97, height: 97 }),
        useSpring({ width: hovered ? 435 : 97, height: 97 }),
        useSpring({ width: hovered ? 324 : 97, height: 97 }),
        useSpring({ width: hovered ? 224 : 97, height: 97 }),
        useSpring({ width: hovered ? 1060 : 97, height: 97 }),
        useSpring({ width: hovered ? 755 : 97, height: 97 }),
    ];

    return (
        <div
            ref={ref}
            className="Sevencontainer"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className="row1">
                <span className="text">CUSTOM</span>
                <animated.span style={springs[0]} className="circle green expand-left"></animated.span>
                <span className="text">CREATIVES</span>
            </div>
            <div className="row2">
                <animated.span style={springs[1]} className="circle blue"></animated.span>
                <span className="text">GAMIFICATION</span>
                <animated.span style={springs[2]} className="circle orange"></animated.span>
            </div>
            <div className="row3">
                <span className="text">SUPPORT</span>
                <animated.span style={springs[3]} className="circle orange"></animated.span>
                <span className="text">STRATEGY</span>
                <animated.span style={springs[4]} className="circle pink"></animated.span>
            </div>
            <div className="row4">
                <animated.span style={springs[5]} className="circle green"></animated.span>
                <span className="text">APPROACHE</span>
            </div>
            <div className="row5">
                <span className="text">DEVELOPMENT</span>
                <animated.span style={springs[6]} className="circle blue"></animated.span>
                <span className="text">AI</span>
            </div>
        </div>
    );
};

export default SevenScreenDesign;
