import React, { useState, useEffect } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import '../css/SixScreenDesign.css';

const logos = [
  { id: 1, name: 'coca-cola', src: 'icons/cola.png', initialPosition: { x: 730, y: -200 }, targetPosition: { x: 1300, y: -200 } },
  { id: 2, name: 'gorilla', src: 'icons/gorilla.png', initialPosition: { x: 465, y: -200 }, targetPosition: { x: 750, y: -200 } },
  { id: 3, name: 'unilever', src: 'icons/unilever.png', initialPosition: { x: 200, y: -200 }, targetPosition: { x: 200, y: -200 } },

  { id: 4, name: 'pepsico', src: 'icons/pepsico.png', initialPosition: { x: -640, y: 100 }, targetPosition: { x: 500, y: 100 } },
  { id: 5, name: 'samsung', src: 'icons/samsung.png', initialPosition: { x: -900, y: 100 }, targetPosition: { x: -40, y: 100 } },
  { id: 6, name: 'beko', src: 'icons/beko.png', initialPosition: { x: 160, y: -165 }, targetPosition: { x: 730, y: -165 } },
  { id: 7, name: 'visa', src: 'icons/visa.png', initialPosition: { x: -105, y: -165 }, targetPosition: { x: 175, y: -165 } },
  { id: 8, name: 'teva', src: 'icons/teva.png', initialPosition: { x: -375, y: -165 }, targetPosition: { x: -375, y: -165 } },

  { id: 9, name: 'lg', src: 'icons/lg.png', initialPosition: { x: -915, y: 145 }, targetPosition: { x: -635, y: 145 } },
  { id: 10, name: 'hyundai', src: 'icons/hyundai.png', initialPosition: { x: -1180, y: 145 }, targetPosition: { x: -1180, y: 145 } },
  { id: 11, name: 'kia', src: 'icons/kia.png', initialPosition: { x: -120, y: -120 }, targetPosition: { x: 445, y: -120 } },
  { id: 12, name: 'bosch', src: 'icons/bosch.png', initialPosition: { x: -385, y: -120 }, targetPosition: { x: 470, y: -120 } },
  { id: 13, name: 'tui', src: 'icons/tui.png', initialPosition: { x: -650, y: -120 }, targetPosition: { x: 490, y: -120 } },
  { id: 14, name: 'alfabank', src: 'icons/bank.png', initialPosition: { x: -915, y: -120 }, targetPosition: { x: 500, y: -120 } },
];

const SixScreenDesign = () => {
  const [hovered, setHovered] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.6,
  });

  useEffect(() => {
    if (inView) {
      setHovered(true);
    } else {
      setHovered(false);
    }
  }, [inView]);

  const springs = useSprings(
    logos.length,
    logos.map(logo => ({
      transform: hovered
        ? `translate(${logo.targetPosition.x}px, ${logo.targetPosition.y}px)`
        : `translate(${logo.initialPosition.x}px, ${logo.initialPosition.y}px)`,
    }))
  );

  const imgSprings = useSprings(
    logos.length,
    logos.map(() => ({
      transform: hovered ? `translateY(0%)` : `translateY(-520%)`,
      config: { tension: 200, friction: 20 } // Adjust the animation configuration as needed
    }))
  );

  return (
    <div
      ref={ref}
      className="six-screen-design"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="title">
        <svg
          width="672"
          height="182"
          viewBox="0 0 672 182"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49.29 91.04L108.624 158H87.42L31.248 95.504V158H13.95V27.8H31.248V86.576L85.56 27.8H106.764L49.29 91.04ZM119.746 65H188.752V158H172.57V80.624H135.928V118.568C135.928 133.448 132.952 144.05 127 150.374C121.048 156.574 112.306 159.116 100.774 158V142.748C107.222 143.616 111.996 142.19 115.096 138.47C118.196 134.626 119.746 127.868 119.746 118.196V65ZM218.108 130.286L269.63 65H282.65V158H266.654V92.714L215.132 158H202.112V65H218.108V130.286ZM457.758 103.688V65H473.94V158H457.758V119.126H409.584V158H393.402V65H409.584V103.688H457.758ZM476.996 65H556.604V80.624H524.984V158H508.802V80.624H476.996V65ZM575.807 98.48H598.871C608.171 98.48 615.859 101.27 621.935 106.85C628.135 112.306 631.235 119.436 631.235 128.24C631.235 137.044 628.135 144.236 621.935 149.816C615.735 155.272 608.047 158 598.871 158H559.625V65H575.807V98.48ZM643.325 158V65H659.507V158H643.325ZM575.807 142.934H598.871C603.583 142.934 607.489 141.57 610.589 138.842C613.689 136.114 615.239 132.58 615.239 128.24C615.239 123.9 613.627 120.366 610.403 117.638C607.303 114.786 603.459 113.36 598.871 113.36H575.807V142.934Z"
            fill="black"
          />
          <path
            d="M385.402 118.94H307.468C309.08 127.124 312.862 133.572 318.814 138.284C324.766 142.872 332.206 145.166 341.134 145.166C353.41 145.166 362.338 140.64 367.918 131.588L381.682 139.4C372.506 153.412 358.866 160.418 340.762 160.418C326.13 160.418 314.102 155.83 304.678 146.654C295.502 137.23 290.914 125.512 290.914 111.5C290.914 97.364 295.44 85.708 304.492 76.532C313.544 67.232 325.262 62.582 339.646 62.582C353.286 62.582 364.384 67.418 372.94 77.09C381.62 86.514 385.96 98.046 385.96 111.686C385.96 114.042 385.774 116.46 385.402 118.94ZM339.646 77.834C331.09 77.834 323.898 80.252 318.07 85.088C312.366 89.924 308.832 96.496 307.468 104.804H369.592C368.228 96.248 364.818 89.614 359.362 84.902C353.906 80.19 347.334 77.834 339.646 77.834Z"
            fill="#D2F65A"
          />
          <path
            d="M309.246 91.2617V100.344H363.738V91.2617H354.656V82.1797H318.328V91.2617H309.246ZM381.902 136.672V145.754H372.82V154.836H363.738V163.918H309.246V154.836H300.164V145.754H291.082V82.1797H300.164V73.0977H309.246V64.0156H363.738V73.0977H372.82V82.1797H381.902V109.426H372.82V118.508H309.246V136.672H318.328V145.754H354.656V136.672H381.902Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="logos">
        {springs.map((style, index) => (
          <animated.div
            key={logos[index].id}
            className="logo-card"
            style={{
              ...style,
              '--target-x': `${logos[index].targetPosition.x}px`,
              '--target-y': `${logos[index].targetPosition.y}px`,
            }}
          >
            <animated.img
              src={logos[index].src}
              alt={logos[index].name}
              style={imgSprings[index]}
            />
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default SixScreenDesign;
