import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Box,ScrollControls,Scroll } from '@react-three/drei'; // дополнительная библиотека для упрощения работы
import * as THREE from 'three'
import { Balls } from '../components/Balls'; // дополнительная библиотека для упрощения работы
import { BallsDesign } from '../components/Balls_design'; // дополнительная библиотека для упрощения работы
import '../index.css';
import { Suspense } from "react";
import  TwoScreen_design  from '../components/TwoScreen_design'; // дополнительная библиотека для упрощения работы
import  ThreeScreenDesign  from '../components/ThreeScreen_design'; // дополнительная библиотека для упрощения работы
import  FourScreenDesign  from '../components/FourScreen_design'; // дополнительная библиотека для упрощения работы
import  SixScreenDesign  from '../components/SixScreen_design'; // дополнительная библиотека для упрощения работы
import  SevenScreenDesign  from '../components/SevenScreen_design'; // дополнительная библиотека для упрощения работы
import  EightScreenDesign  from '../components/EightScreen_design'; // дополнительная библиотека для упрощения работы
import  NineScreenDesign  from '../components/NineScreen'; // дополнительная библиотека для упрощения работы
import  {FooterDesign}  from '../components/NineScreen_design'; // дополнительная библиотека для упрощения работы

import StyledTestimonialSection from '../components/FiveScreen_design';

import { Gallery } from '../components/Gallery';
import { GalleryDesign } from '../components/GalleryDesign';

// Простой компонент для создания 3D куба
function Cube() {
  return (
    <mesh>
      <boxGeometry attach="geometry" args={[2, 2, 2]} />
      <meshStandardMaterial attach="material" color="royalblue" />
    </mesh>
  );
}
function OneScreen() {
  return (
    <div className="OneScreen" id="main">
      <BallsDesign />
      <Suspense fallback={<div>Loading...</div>}>
      <Balls />
        
      </Suspense>
     
    </div>
  );
}

function TwoScreen() {
  return (
    <div className="TwoScreen" id="map">
      <TwoScreen_design />
      
      
        
      
     
    </div>
  );
}
function ThreeScreen() {
  return (
    <div className="ThreeScreen" id="eco">
      <ThreeScreenDesign />
      

      
     
    </div>
  );
}
function FourScreen() {
  return (
    <div className="FourScreen" id="gallery">
      <FourScreenDesign />
      

      
     
    </div>
  );
}
function FiveScreen() {
  return (
    <div className="FiveScreen" id="about-us">
      <StyledTestimonialSection />
      

      
     
    </div>
  );
}
function SixScreen() {
  return (
    <div className="SixScreen">
      <SixScreenDesign />
      

      
     
    </div>
  );
}
function SevenScreen() {
  return (
    <div className="SevenScreen">
      <SevenScreenDesign />
      

      
     
    </div>
  );
}
function EightScreen() {
  return (
    <div className="EightScreen">
      
      

      <EightScreenDesign />
      
     
    </div>
  );
}
function NineScreen() {
  return (
    <div className="NineScreen">
      <FooterDesign/>
      <NineScreenDesign />
      

      
     
    </div>
  );
}
// Компонент App, в котором рендерится Canvas и Cube
function App() {
  return (
    <>
    
    {/* <Canvas>
    <ScrollControls pages={6} damping={0.1}>

      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Cube />
          </ScrollControls>

    </Canvas> */}
   
    
    <OneScreen/>
    <TwoScreen/>
    <ThreeScreen/>
    <FourScreen/>
    <FiveScreen/>
    <SixScreen/>
    <SevenScreen/>
    <EightScreen/>
    {/* <NineScreen/> */}

    </>
  );
}

export default App;
