import React from 'react';
import '../css/Header.css';
import globeIcon from '../icons/globe.svg';
import minusIcon from '../icons/min.svg';

const Header = () => {
    return (
        <div className="Globalheader">
            <div className="menu">
                <nav className="menu-items">
                    <a href="#main" className="menu-item">Главная</a>
                    <a href="#about-us" className="menu-item">О нас</a>
                    <a href="#gallery" className="menu-item">Галерея</a>
                    <a href="#cases" className="menu-item">Кейсы</a>
                    {/* <a href="#blog" className="menu-item">Блог</a> */}
                </nav>
                <div className="menu-icons">
                    {/* <div className="icon">
                        <img src={globeIcon} alt="Globe" />
                    </div>
                    <div className="icon">
                        <img src={minusIcon} alt="Minus" />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Header;
