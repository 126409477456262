import React, { useRef, useEffect, useState } from 'react';
import '../css/FiveScreenDesign.css'; // Ensure the CSS file path is correct

const reviews = [
  {
    id: 1,
    company: 'Raiting Point MediaCom',
    text: (
      <>
        Мы начали сотрудничество с HiTarget в 2022 году и за это время оценили все преимущества и возможности данной Programmatic платформы. Мы использовали HiTarget для охвата аудитории Gen Z и разработки Rich Media форматов для захвата внимания. Из плюсов хочется выделить: низкую стоимость, круглосуточную поддержку и высокие показатели охвата. Минусы: рекламный кабинет простой, но ожидаются улучшения. <br /> <br />
        Поддержка всегда готова быстро вовлечься и предоставить решение, что особенно ценно при крупных кампаниях. Мы рекомендуем HiTarget как отличную альтернативу другим programmatic платформам благодаря персонализированному подходу и эффективному сотрудничеству.
      </>
    ),
    name: 'Рошонок Алексей Эдуардович',
    position: (
      <>
        Digital Strategic Director,<br /> DASM Coca-Cola.
      </>
    ),
    image: 'roshonok.png' // Replace with the actual path to the image
  },
  {
    id: 2,
    company: 'ADV Group',
    text: (
      <>
        Мы очень довольны работой с таким ответственным и инновационным поставщиком как HiTarget.<br />  
        Коллеги берут в работу запросы любой сложности и предлагают множество вариаций размещения сверх наших запросов, что делает наше трехстороннее сотрудничество с клиентом еще лучше и эффективнее.<br />  
        Самое главное - это сервис, который оказывает Hitarget. Все запросы отрабатываются в очень ускоренном режиме, не теряя качества. <br /> 
        Конечно, есть и минусы, коллеги слишком проактивные, но зачастую это приводит к невероятным результатам.<br /> 
      </>
    ),
    name: 'Сарсембаев Жангир Есенұлы',
    position: (
      <>
        Digital buying director,<br /> ADV Group. 
      </>
    ),
    image: 'zhangir.png' // Replace with the actual path to the image
  },
  {
    id: 3,
    company: 'Gorilla Asia',
    text: (
      <>
        Уважаемая команда Hi Target, <br /><br />

        Компания Gorilla Asia искренне благодарит вас за плодотворное сотрудничество. Мы ценим вашу ответственность и инновационный подход. <br /><br />

        Ваш профессионализм и способность обрабатывать сложные запросы сделали наше сотрудничество более эффективным. Ваши разнообразные варианты размещения и оперативность значительно улучшили наши показатели KPI по Digital кампании!<br /> 
        Особенно хочется отметить высочайший уровень сервиса. Каждая задача выполняется быстро и качественно. 
      </>
    ),
    name: 'Жумабай Нурсултан',
    position: (
      <>
        Бренд Менеджер,<br /> Gorilla Asia. 
      </>
    ),
    image: 'nurs.png' // Replace with the actual path to the image
  },
];

const FiveScreenDesign = () => {
  const reviewsRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      if (reviewsRef.current) {
        const element = reviewsRef.current;
        if (
          (event.deltaY > 0 && element.scrollLeft + element.clientWidth < element.scrollWidth) ||
          (event.deltaY < 0 && element.scrollLeft > 0)
        ) {
          event.preventDefault();
          element.scrollLeft += event.deltaY;
        }
      }
    };

    const handleMouseDown = (event) => {
      setIsDragging(true);
      setStartX(event.pageX - reviewsRef.current.offsetLeft);
      setScrollLeft(reviewsRef.current.scrollLeft);
    };

    const handleMouseMove = (event) => {
      if (!isDragging) return;
      event.preventDefault();
      const x = event.pageX - reviewsRef.current.offsetLeft;
      const walk = (x - startX) * 2; // Multiply by 2 for faster scrolling
      reviewsRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const reviewsElement = reviewsRef.current;
    if (reviewsElement) {
      reviewsElement.addEventListener('wheel', handleScroll, { passive: false });
      reviewsElement.addEventListener('mousedown', handleMouseDown);
      reviewsElement.addEventListener('mousemove', handleMouseMove);
      reviewsElement.addEventListener('mouseup', handleMouseUp);
      reviewsElement.addEventListener('mouseleave', handleMouseUp);
    }

    return () => {
      if (reviewsElement) {
        reviewsElement.removeEventListener('wheel', handleScroll);
        reviewsElement.removeEventListener('mousedown', handleMouseDown);
        reviewsElement.removeEventListener('mousemove', handleMouseMove);
        reviewsElement.removeEventListener('mouseup', handleMouseUp);
        reviewsElement.removeEventListener('mouseleave', handleMouseUp);
      }
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div className="five-screen-design">
      <div className="intro">
        <h2>Клиенты о нас</h2>
        <p>Мы стремимся выстраивать <br /> доверительные и долгосрочные<br /> отношения с нашими клиентами.<br /> Наша цель — не просто<br /> предоставить услуги, а стать<br /> надежным партнером, который помогает вам достигать поставленных целей и развивать бизнес.</p>
      </div>
      <div className="reviews" ref={reviewsRef}>
        {reviews.map(review => (
          <div key={review.id} className="review">
            <div className="company">{review.company}</div>
            <div className="review-content">
              <div className="review-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_210_36851)">
                    <path d="M6.99609 6.99854V13.9985H-0.00390625V6.89854C-0.00390625 2.09854 4.49609 1.49854 4.49609 1.49854L5.09609 2.89854C5.09609 2.89854 3.09609 3.19854 2.69609 4.79854C2.29609 5.99854 3.09609 6.99854 3.09609 6.99854H6.99609ZM15.9961 6.99854V13.9985H8.99609V6.89854C8.99609 2.09854 13.4961 1.49854 13.4961 1.49854L14.0961 2.89854C14.0961 2.89854 12.0961 3.19854 11.6961 4.79854C11.2961 5.99854 12.0961 6.99854 12.0961 6.99854H15.9961Z" fill="black" />
                  </g>
                  <defs>
                    <clipPath id="clip0_210_36851">
                      <rect width="16" height="16" fill="white" transform="translate(-0.00390625)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p className="review-text">{review.text}</p>
              <div className="review-footer">
                <div className="reviewer">
                  <img src={review.image} alt={review.name} className="reviewer-image" />
                  <div className="reviewer-info">
                    <div className="reviewer-name">{review.name}</div>
                    <div className="reviewer-position">{review.position}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FiveScreenDesign;
