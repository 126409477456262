import * as React from "react";
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate для перенаправления
import VanillaTilt from 'vanilla-tilt';

import '../css/FourScreenDesign.css'; // Убедитесь, что путь к CSS правильный

const cardData = [
  {
    id: 1,
    title: "DODO PIZZA",
    content: "Action Rich media • in app • mobile • Desktop",
    link: "/dodo-case", // Обновлено для перенаправления на /cola-case
    iframeSrc: "dodo300600/index.html",
    tiltOptions: { max: 25, speed: 400, glare: true }
  },
  {
    id: 2,
    title: "FLINT",
    content: "Action Rich media • in app • mobile • Desktop",
    link: "/flint-case",
    iframeSrc: "flintBlue300x600/index.html",
    tiltOptions: { max: 20, speed: 300, glare: true }
  },
  {
    id: 3,
    title: "BANK CENTER CREDIT",
    content: "FULLSCREEN • FINTECH • MOBILE",
    link: "/bcc-case",
    iframeSrc: "BCK300x600/index.html",
    tiltOptions: { max: 20, speed: 300, glare: true }
  },
  {
    id: 4,
    title: "Range Rover",
    content: "Brand RM • Auto • Mobile • Desktop",
    link: "/",
    iframeSrc: "range/index.html",
    tiltOptions: { max: 20, speed: 300, glare: true }
  }
];

function FourScreenDesign() {
  const tiltRefs = useRef([]);
  const navigate = useNavigate(); // Используем хук navigate для перенаправления

  useEffect(() => {
    tiltRefs.current.forEach((tiltRef, index) => {
      if (tiltRef) {
        VanillaTilt.init(tiltRef, cardData[index].tiltOptions);
      }
    });

    return () => tiltRefs.current.forEach(tiltRef => tiltRef?.vanillaTilt.destroy());
  }, []);

  const handleCardClick = (link) => {
    navigate(link); // Перенаправляем на указанный путь
  };

  return (
    <>
      <main className="main-container">
        <div className="title-container">
          {/* SVG код вашего заголовка */}
        </div>
        <section className="content-wrapper">
          <div className="grid-container">
            <div className="column">
              <div className="info-box">
                <p className="description">
                  Свыше 150 клиентов предпочли нас благодаря нашим инновационным подходам и вниманию к деталям, обеспечивающим гарантированные результаты
                </p>
                <div className="gallery-button">
                  <span className="button-content">
                    <span className="button-text">Галерея креативов</span>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_749_789)">
                        <path d="M14.2719 7.66061L3.26502 18.6675M14.2719 7.66061V17.0951M14.2719 7.66061H4.83744" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_749_789">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                        </clipPath>
                        </defs>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="cards">
              {cardData.map((card, index) => (
                <div
                  key={card.id}
                  ref={el => tiltRefs.current[index] = el}
                  className="card"
                  onClick={() => handleCardClick(card.link)} // Добавлен обработчик клика
                  style={{ cursor: 'pointer' }}
                >
                  <iframe
                    src={card.iframeSrc}
                    title={card.title}
                   
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    className="project-iframe"
                  />
                  <div className="cardbox">
                    <p className="project-type">{card.content}</p>
                    <h3 className="project-title">{card.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default FourScreenDesign;
