import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import './ColaCasePage.css';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { Html,Decal, Environment, useGLTF, ContactShadows, OrbitControls ,useTexture } from '@react-three/drei';
import { Suspense, useRef } from 'react';
import  EightScreenDesign  from '../components/EightScreen_design'; // дополнительная библиотека для упрощения работы
import { useNavigate } from 'react-router-dom';

function Model(props) {
  const decalTexture = useTexture('Vector.png');

  const group = useRef();
  const { nodes, materials } = useGLTF('/mac-draco.glb');
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 20 + 0.25, 0.1);
    group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 20, 0.1);
    group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 8) / 20, 0.1);
    group.current.position.y = THREE.MathUtils.lerp(group.current.position.y, (-2 + Math.sin(t / 2)) / 2, 0.1);
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation-x={-0.425} position={[0, -0.04, 0.41]}>
        <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh material={materials.aluminium} geometry={nodes['Cube008'].geometry} />
          <mesh material={materials['matte.001']} geometry={nodes['Cube008_1'].geometry}>
    
          </mesh>
          <mesh material={materials['aluminium']} geometry={nodes['Cube008'].geometry}>
            <Decal
                // debug // Makes "bounding box" of the decal visible
              position={[0, 0.2, 0]} // Позиция декали
              rotation={[2, 0, 0]} // Поворот декали
              scale={[3.5, 1.5, 2.5]} // Масштаб декали
              map={decalTexture} // Карта текстуры декали
              depthTest={true}
              depthWrite={true}
              transparent // Включает прозрачность
              alphaTest={0} // Управляет уровнем отсечения альфа-канала
              metalness={1} // Уровень металличности (1 для полного металлического эффекта)
              roughness={0} // Уровень шероховатости (0 для гладкой поверхности)
              envMapIntensity={1} // Интенсивность окружения (для контроля отражений)
            />
          </mesh>
          <mesh geometry={nodes['Cube008_2'].geometry}>
            <Html 
              className="content" 
              rotation-x={-Math.PI / 2} 
              position={[0.3, 0.04, 0.0]} 
              transform 
              scale={[0.8, 0.8, 0.8]} 
              occlude
            >
              <div className="wrapper">
                <iframe 
                  src="dodo300250/index.html" 
                  title="Embedded Content" 
                  className="embedded-iframe1"
                  scrolling="no" 
                />
              </div>
            </Html>
          </mesh>
        </group>
      </group>
      <mesh material={materials.keys} geometry={nodes.keyboard.geometry} position={[1.79, 0, 3.45]} />
      <group position={[0, -0.1, 3.39]}>
        <mesh material={materials.aluminium} geometry={nodes['Cube002'].geometry} />
        <mesh material={materials.trackpad} geometry={nodes['Cube002_1'].geometry} />
      </group>
      <mesh material={materials.touchbar} geometry={nodes.touchbar.geometry} position={[0, -0.03, 1.2]} />
    </group>
  );
}

export default function ColaCasePage() {
  const creativesData = {
    labels: ['320x480', '300x250', '300x600'],
    datasets: [{
      data: [29, 32, 38],
      backgroundColor: ['#f15a24', '#0D24F7','#F04E9F'],
      hoverBackgroundColor: ['#ccc', '#ccc'],
    }]
  };

  const genderData = {
    labels: ['Женщины', 'Мужчины'],
    datasets: [{
      data: [48, 52],
      backgroundColor: ['#D2F65A', '#0D24F7'],
      hoverBackgroundColor: ['#ccc', '#ccc'],
    }]
  };

  const cityData = {
    labels: ['Алматы', 'Астана', 'Усть-Каменогорск', 'Другие города'],
    datasets: [{
      data: [10, 8, 4, 78],
      backgroundColor: ['#0D24F7', '#FF6800' ,'#F04E9F' , '#D2F65A'],
      hoverBackgroundColor: ['#ccc', '#ccc'],
    }]
  };

  const options = {
    cutout: '80%',
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      }
    }
  };
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate('/'); // Перенаправляем на главную страницу
  };
  return (
    <div className="case-study-container">
        <p className="logocase">
          
          <svg width="218" height="35" viewBox="0 0 218 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M56.0542 1.81641V10.2919L39.0453 27.4053H30.6321V20.7479L24.0173 27.4053H15.6041V20.7479L8.98939 27.4053H0.576172V18.9378L17.5877 1.81641H26.001V8.47383L32.613 1.81641H41.0289V8.47383L47.641 1.81641H56.0542Z" fill="#D2F65A"/>
              <path d="M77.3737 17.668V27.5623H72.5918V1.81641H77.3737V17.6707C77.3737 16.3185 77.58 15.0753 77.9899 13.944C78.3999 12.8127 78.942 11.8864 79.6138 11.1623C80.2856 10.4383 81.0447 9.87931 81.8884 9.48269C82.7321 9.08873 83.6075 8.88908 84.5121 8.88908C86.9744 8.88908 88.921 9.68765 90.3545 11.2848C91.788 12.8819 92.5048 14.9981 92.5048 17.6308V27.5597H87.7229V17.7026C87.7229 16.4808 87.2653 15.3921 86.3502 14.4338C85.4351 13.4755 84.3692 12.9964 83.1579 12.9964C81.5155 12.9964 80.1428 13.4569 79.0373 14.3779C77.9317 15.2989 77.3789 16.3956 77.3789 17.6627L77.3737 17.668Z" fill="black"/>
              <path d="M98.135 5.97986C97.3257 5.97986 96.6486 5.70835 96.1012 5.16266C95.5537 4.61697 95.2812 3.94617 95.2812 3.14228C95.2812 2.33838 95.5537 1.66758 96.1012 1.12189C96.6486 0.576202 97.3257 0.304688 98.135 0.304688C98.9443 0.304688 99.6003 0.57354 100.142 1.11391C100.685 1.65161 100.954 2.32773 100.954 3.14228C100.954 3.95682 100.685 4.63294 100.142 5.17065C99.6003 5.70835 98.9311 5.97986 98.135 5.97986ZM95.7626 27.5626V9.21408H100.579V27.5626H95.7626Z" fill="black"/>
              <path d="M111.544 6.26711C111.544 7.47561 111.885 8.31677 112.57 8.79059C113.252 9.26441 114.355 9.49866 115.878 9.49866H117.592V13.5554H111.544V20.503C111.544 22.1906 112.221 23.0344 113.577 23.0344H117.592V27.5597H111.972C110.306 27.5597 109.026 27.0273 108.126 25.9625C107.227 24.8978 106.78 23.4311 106.78 21.5624V13.5554H102.908V9.49866H114.344C113.429 9.49866 112.739 9.49333 112.276 9.48003C111.813 9.46938 111.253 9.42945 110.599 9.3629C109.946 9.29635 109.467 9.19786 109.163 9.06743C108.859 8.937 108.518 8.748 108.137 8.50311C107.756 8.25821 107.486 7.94677 107.325 7.56878C107.164 7.19079 107.029 6.73028 106.915 6.18725C106.801 5.64156 106.746 5.00004 106.746 4.25737V1.81641H111.544V6.26977V6.26711Z" fill="black"/>
              <path d="M127.077 28.0474C126.162 28.0474 125.276 27.925 124.419 27.6801C123.562 27.4352 122.785 27.0785 122.081 26.6126C121.38 26.1468 120.817 25.5372 120.394 24.7892C119.971 24.0412 119.762 23.2001 119.762 22.2658C119.762 21.3314 119.992 20.5488 120.449 19.8408C120.907 19.1354 121.513 18.579 122.269 18.1718C123.023 17.7645 123.819 17.4504 124.649 17.2294C125.482 17.0085 126.35 16.8727 127.255 16.8248L135.549 16.4122V15.9464C135.549 13.8888 133.724 12.8586 130.071 12.8586C126.419 12.8586 124.631 13.8408 124.631 15.8027H120.206C120.206 14.8098 120.381 13.9234 120.732 13.1461C121.084 12.3688 121.552 11.7326 122.142 11.2428C122.732 10.753 123.446 10.3458 124.292 10.021C125.136 9.69892 126.017 9.46999 126.932 9.33956C127.847 9.20913 128.847 9.14258 129.928 9.14258C133.282 9.14258 135.837 9.77079 137.591 11.0272C139.344 12.2836 140.222 14.312 140.222 17.115V27.5283H135.62V20.9747C135.62 22.1247 135.398 23.1415 134.951 24.0279C134.504 24.9144 133.964 25.6011 133.327 26.0936C132.69 26.5834 131.957 26.9853 131.124 27.2968C130.291 27.6082 129.553 27.8105 128.913 27.9063C128.27 28.0022 127.657 28.0501 127.075 28.0501L127.077 28.0474ZM128.72 24.5816C129.291 24.5577 129.854 24.5071 130.415 24.4299C130.973 24.3527 131.576 24.2223 132.227 24.0439C132.875 23.8656 133.443 23.642 133.93 23.3784C134.417 23.1149 134.821 22.7609 135.144 22.319C135.464 21.8771 135.625 21.3793 135.625 20.8283V19.6624L129.347 19.9499C128.847 19.9739 128.365 20.0191 127.903 20.0857C127.44 20.1522 126.937 20.256 126.395 20.3998C125.853 20.5435 125.419 20.7591 125.094 21.0466C124.766 21.3341 124.602 21.6801 124.602 22.0874C124.602 22.673 124.835 23.1655 125.297 23.5594C125.76 23.9534 126.289 24.2276 126.884 24.3766C127.479 24.5257 128.09 24.5949 128.722 24.5843L128.72 24.5816Z" fill="black"/>
              <path d="M155.958 9.17676V13.7739H152.586C151.824 13.7739 151.179 13.859 150.65 14.0241C150.121 14.1918 149.701 14.4101 149.394 14.6789C149.084 14.9478 148.844 15.3151 148.672 15.7836C148.5 16.2494 148.386 16.7153 148.333 17.1758C148.28 17.6363 148.254 18.2086 148.254 18.89V27.5625H143.385V9.21402H148.22V16.3772C148.22 14.2823 148.772 12.56 149.878 11.2051C150.983 9.85288 152.293 9.17676 153.803 9.17676H155.961H155.958Z" fill="black"/>
              <path d="M174.312 16.2679V9.17657H179.057V25.4621C179.057 28.3822 178.171 30.6901 176.399 32.383C174.627 34.076 172.188 34.9225 169.083 34.9225H161.627V30.722H169.155C170.998 30.722 172.318 30.3201 173.114 29.5188C173.91 28.7176 174.309 27.3653 174.309 25.4621V20.1649C174.143 21.0992 173.899 21.9484 173.577 22.715C173.257 23.4816 172.902 24.1205 172.516 24.6369C172.13 25.1506 171.696 25.6032 171.215 25.9918C170.734 26.3804 170.268 26.6839 169.824 26.8995C169.377 27.1151 168.911 27.2882 168.425 27.4212C167.938 27.5543 167.52 27.6395 167.176 27.6821C166.832 27.7247 166.491 27.746 166.161 27.746C164.732 27.746 163.479 27.5091 162.397 27.0379C161.315 26.5641 160.445 25.8986 159.792 25.0362C159.139 24.1737 158.647 23.1835 158.321 22.0655C157.993 20.9475 157.832 19.7044 157.832 18.3388C157.832 15.3096 158.546 12.9405 159.972 11.2289C161.4 9.51729 163.574 8.66016 166.502 8.66016C167.036 8.66016 167.56 8.70541 168.073 8.79591C168.583 8.88642 169.181 9.08872 169.866 9.40549C170.548 9.72226 171.165 10.1428 171.712 10.6619C172.26 11.181 172.773 11.9236 173.254 12.8873C173.735 13.8509 174.09 14.9795 174.315 16.2705L174.312 16.2679ZM174.275 18.3335C174.275 16.6352 173.717 15.3335 172.598 14.4285C171.479 13.5261 170.041 13.0736 168.282 13.0736C166.639 13.0736 165.285 13.5288 164.214 14.4391C163.143 15.3495 162.609 16.6485 162.609 18.3362C162.609 19.4142 162.881 20.3459 163.429 21.1365C163.976 21.9271 164.669 22.5047 165.507 22.8694C166.346 23.2341 167.271 23.4177 168.282 23.4177C170.006 23.4177 171.437 22.9785 172.572 22.0974C173.706 21.2164 174.275 19.9626 174.275 18.3362V18.3335Z" fill="black"/>
              <path d="M191.658 28.0128C188.601 28.0128 186.194 27.1903 184.433 25.5453C182.671 23.9002 181.793 21.5684 181.793 18.5524C181.793 15.5365 182.631 13.0157 184.308 11.4239C185.985 9.83204 188.387 9.03613 191.515 9.03613C194.644 9.03613 197.138 9.85334 198.892 11.4877C200.645 13.1222 201.523 15.4886 201.523 18.5897V19.918H186.252C186.252 21.2356 186.773 22.2551 187.813 22.9792C188.852 23.7032 190.159 24.0652 191.727 24.0652C193.131 24.0652 194.271 23.839 195.152 23.3838C196.033 22.9286 196.572 22.3004 196.776 21.4992H201.235C200.415 25.8434 197.22 28.0155 191.656 28.0155L191.658 28.0128ZM186.218 16.8994L196.884 16.9367C196.884 16.1354 196.615 15.4247 196.072 14.8098C195.53 14.1922 194.861 13.7397 194.065 13.4442C193.269 13.1514 192.43 13.005 191.55 13.005C190.611 13.005 189.751 13.1301 188.971 13.383C188.191 13.6332 187.538 14.0645 187.009 14.6767C186.48 15.2863 186.215 16.029 186.215 16.9021L186.218 16.8994Z" fill="black"/>
              <path d="M211.866 6.26711C211.866 7.47561 212.207 8.31677 212.892 8.79059C213.574 9.26441 214.677 9.49866 216.201 9.49866H217.915V13.5554H211.866V20.503C211.866 22.1906 212.543 23.0344 213.9 23.0344H217.915V27.5597H212.294C210.628 27.5597 209.348 27.0273 208.449 25.9625C207.55 24.8978 207.103 23.4311 207.103 21.5624V13.5554H203.231V9.49866H214.667C213.752 9.49866 213.061 9.49333 212.599 9.48003C212.136 9.46938 211.575 9.42945 210.922 9.3629C210.268 9.29635 209.79 9.19786 209.486 9.06743C209.181 8.937 208.84 8.748 208.459 8.50311C208.079 8.25821 207.809 7.94677 207.647 7.56878C207.486 7.19079 207.351 6.73028 207.237 6.18725C207.124 5.64156 207.068 5.00004 207.068 4.25737V1.81641H211.866V6.26977V6.26711Z" fill="black"/>
          </svg>
          
        </p>
        
<div className="headercase">
    <div className="back-linkcase"><a href="/">← Назад</a></div>
    <div className="title-containercase">
        <div className="industrycase">RICH MEDIA • FMCG • MOBILE • DESKTOP</div>
        <h1 className="titlecase">DODO PIZZA</h1>
    </div>
</div>

      <div className="info">
        <div className="geo-age-targeting">
          <div className="geo"><span>Гео:</span> Казахстан, Шымкент</div>
          <div className="age"><span>ЦА:</span> 18-54 ALL</div>
          <div className="targeting">
            <h3>Таргетинги:</h3>
            <p>ALL</p>
          </div>
        </div>
        <div className="task">
          <h3>Задача:</h3>
          <p>Продвижение акции, отдельный упор на аудиторию Шымкента (3% KPI).<br></br> Охват и узнаваемость продукта. <br></br> Посещение сайта.</p>
        </div>
        <div className="solution">
          <h3>Решение:</h3>
          <p>Разработка акционного креатива Rich Media.<br></br>Цель креатива: информативно рассказать об акции, показать аудитории на какую пиццу распространяется акция и дать возможность выбора.<br></br>Разбивка кампаний на Все города КЗ и Шымкент отдельно.<br></br></p>
          <p>Использование Impression Tag|Click Tag для отслеживания размещения.<br></br>Оптимизация кампании на клики, для увеличения посещаемости сайта.<br></br>Добавление WL в размещения, отсев площадок с низкими CTR% для оптимизации кликов.<br></br>По итогам кампании PI около 21%, AET 00:01:12</p>
        </div>
      </div>

      <div className="banner">
        <Canvas style={{ height: '790px', width: '100%' }} camera={{ position: [-5, 0, -15], fov: 45 }}>
          <pointLight position={[10, 10, 10]} intensity={1.5} />
          <Suspense fallback={null}>
            <group rotation={[0, Math.PI, 0]} position={[0, 0.3, 0]}>
              <Model />
            </group>
            <Environment files="adamsbridge.hdr"/>
          </Suspense>
          <ContactShadows position={[0, -4.5, 0]} scale={20} blur={2} far={4.5} />
          <OrbitControls enablePan={false} enableZoom={false} minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.2} />
        </Canvas>
      </div>

      <div className="results">
        <div className="result">
          <span className="result-value">3,7М</span>
          <span className="result-label">Показы</span>
        </div>
        <div className="result">
          <span className="result-value">0,70%</span>
          <span className="result-label">CTR</span>
        </div>
        <div className="result">
          <span className="result-value">841 024</span>
          <span className="result-label">Охват</span>
        </div>
        <div className="result">
          <span className="result-value">25 905</span>
          <span className="result-label">Клики</span>
        </div>
      </div>

      <div className="charts">
        <div className="chart-section">
          <h2>Разбивка по креативам:</h2>
          <div className="chart-row">
            <div className="chart-container">
              <Doughnut data={creativesData} options={options} />
            </div>
            <div className="chart-data">
              <div>
                <span className="value">1 088 689</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,65%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">247 429</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">7 621</span> 
                <span className="label">Клики</span>
              </div>
              {/* one */}
              <div>
                <span className="value">1 199 704</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,69%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">272 660</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">8 398</span> 
                <span className="label">Клики</span>
              </div>
              {/* two */}
              <div>
                <span className="value">1 412 113</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,75%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">320 935</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">9 885</span> 
                <span className="label">Клики</span>
              </div>
            </div>
          </div>
        </div>

        <div className="chart-section">
          <h2>Разбивка по полу:</h2>
          <div className="chart-row">
            <div className="chart-container">
              <Doughnut data={genderData} options={options} />
            </div>
            <div className="chart-data">
              {/* <div>
                <span className="value">38,3%</span>
                <span className="label">Женщины</span>
              </div> */}
              <div>
                <span className="value">1 780 683</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,63%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">404 701</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">12 465</span> 
                <span className="label">Клики</span>
              </div>
              {/* one */}
              <div>
                <span className="value">1 919 823</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,77%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">436 323</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">13 440</span> 
                <span className="label">Клики</span>
              </div>
            </div>
          </div>
        </div>

        <div className="chart-section">
          <h2>Разбивка по городам:</h2>
          <div className="chart-row">
            <div className="chart-container">
              <Doughnut data={cityData} options={options} />
            </div>
            <div className="chart-data">
              {/* <div>
                <span className="value">33,12%</span>
                <span className="label">Алматы</span>
              </div> */}
              <div>
                <span className="value">378 932</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0.78%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">86 121</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">2 653</span> 
                <span className="label">Клики</span>
              </div>
              {/* one */}
              <div>
                <span className="value">302 701</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,74%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">68 796</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">2 119</span> 
                <span className="label">Клики</span>
              </div>
              {/* two */}
              <div>
                <span className="value">133 958</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,65%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">30 445</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">938</span> 
                <span className="label">Клики</span>
              </div>
              {/* three */}
              <div>
                <span className="value">2 884 914</span> 
                <span className="label">Показы</span>
              </div>
              <div>
                <span className="value">0,69%</span> 
                <span className="label">CTR</span>
              </div>
              <div>
                <span className="value">655 662</span> 
                <span className="label">Охват</span>
              </div>
              <div>
                <span className="value">20 196</span> 
                <span className="label">Клики</span>
              </div>
              {/* four */}
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <button className="view-more" onClick={handleViewMoreClick}>Посмотрите другие кейсы</button>
      </div>
      <div className="null">
      
      </div>
      <EightScreenDesign />

    </div>
  );
}
