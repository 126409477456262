import * as THREE from "three";
import { useRef, useState, useMemo } from "react";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Environment, Decal, OrbitControls } from "@react-three/drei";
import { EffectComposer, N8AO } from "@react-three/postprocessing";
import { BallCollider, Physics, RigidBody, CylinderCollider } from "@react-three/rapier";
import { TextureLoader } from 'three';
import { Perf } from 'r3f-perf';
import { useInView } from 'react-intersection-observer';
import '../css/BallsDesign.css'; // Make sure the path is correct based on your project structure

THREE.ColorManagement.legacyMode = false;

const baubleMaterial = new THREE.MeshStandardMaterial({ color: "#0588B0", emissive: "green", metalness: 0.75, roughness: 0.00 });
const sphereGeometry = new THREE.SphereGeometry(1, 28, 28);

function Bauble({ vec = new THREE.Vector3(), scale, r = THREE.MathUtils.randFloatSpread, decalTexture, decalPosition, decalRotation, decalScale, decalMetalness, decalRoughness }) {
  const api = useRef();
  const ref = useRef();

  useFrame((state, delta) => {
    delta = Math.min(0.1, delta);
    if (api.current) {
      api.current.applyImpulse(
        vec
          .copy(api.current.translation())
          .normalize()
          .multiply({ x: -50 * delta * scale, y: -150 * delta * scale, z: -50 * delta * scale }),
      );
    }
  });

  return (
    <RigidBody linearDamping={0.75} angularDamping={0.15} friction={0.2} position={[r(20), r(20) - 25, r(20) - 10]} ref={api} colliders={false} dispose={null}>
      <BallCollider args={[scale]} />
      <CylinderCollider rotation={[Math.PI / 2, 0, 0]} position={[0, 0, 1.2 * scale]} args={[0.15 * scale, 0.275 * scale]} />
      <mesh ref={ref} castShadow receiveShadow scale={scale} geometry={sphereGeometry} material={baubleMaterial}>
        <Decal
          position={decalPosition}
          rotation={decalRotation}
          scale={decalScale}
        >
          <meshStandardMaterial
            map={decalTexture}
            metalness={decalMetalness}
            roughness={decalRoughness}
            transparent
            polygonOffset
            polygonOffsetFactor={-1}
          />
        </Decal>
      </mesh>
    </RigidBody>
  );
}

function Pointer({ vec = new THREE.Vector3() }) {
  const ref = useRef();
  useFrame(({ mouse, viewport }) => {
    vec.lerp({ x: (mouse.x * viewport.width) / 2, y: (mouse.y * viewport.height) / 2, z: 0 }, 0.2);
    ref.current?.setNextKinematicTranslation(vec);
  });
  return (
    <RigidBody position={[100, 100, 100]} type="kinematicPosition" colliders={false} ref={ref}>
      <BallCollider args={[2]} />
    </RigidBody>
  );
}

function RoundButton({ position, rotation, normalSvgUrl, hoverSvgUrl }) {
  const [hovered, setHovered] = useState(false);
  const { gl } = useThree();
  const normalTexture = useLoader(TextureLoader, normalSvgUrl);
  const hoverTexture = useLoader(TextureLoader, hoverSvgUrl);

  const handlePointerOver = (e) => {
    e.stopPropagation();
    setHovered(true);
    gl.domElement.style.cursor = 'pointer';
  };

  const handlePointerOut = (e) => {
    e.stopPropagation();
    setHovered(false);
    gl.domElement.style.cursor = 'auto';
  };

  return (
    <mesh
      position={position}
      rotation={rotation}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <boxGeometry args={[13, 1.1, 1]} />
      <meshBasicMaterial 
        map={hovered ? hoverTexture : normalTexture} 
        opacity={1} 
        transparent
      />
    </mesh>
  );
}

function PresButton({ position, rotation, normalSvgUrl, hoverSvgUrl }) {
  const [hovered, setHovered] = useState(false);
  const { gl } = useThree();
  const normalTexture = useLoader(TextureLoader, normalSvgUrl);
  const hoverTexture = useLoader(TextureLoader, hoverSvgUrl);

  const handlePointerOver = (e) => {
    e.stopPropagation();
    setHovered(true);
    gl.domElement.style.cursor = 'pointer';
  };

  const handlePointerOut = (e) => {
    e.stopPropagation();
    setHovered(false);
    gl.domElement.style.cursor = 'auto';
  };

  return (
    <mesh
      position={position}
      rotation={rotation}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <boxGeometry args={[1.8, 0.2, 1]} />
      <meshBasicMaterial 
        map={hovered ? hoverTexture : normalTexture} 
        opacity={1} 
        transparent
      />
    </mesh>
  );
}

export const Balls = ({ decalPosition = [0, 0, 1], decalRotation = [0, 0, 0], decalScale = [1.3, 0.7, 1], decalMetalness = 1, decalRoughness = 0.5 }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Render only once when in view, can be removed if you want it to re-render on every in view
  });

  const decalTexture = useLoader(TextureLoader, "/ht.svg"); // Replace with your SVG texture URL

  const baubles = useMemo(() => [...Array(50)].map(() => ({ scale: [0.75, 0.75, 1, 1, 1.25][Math.floor(Math.random() * 5)] })), []);

  return (
    <div ref={ref}>
      <Canvas
        shadows
        gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
        camera={{ position: [0, 0, 20], fov: 32.5, near: 1, far: 100 }}
        onCreated={(state) => (state.gl.toneMappingExposure = 1.5)}
      >
        <ambientLight intensity={1} />
        <spotLight position={[20, 20, 25]} penumbra={1} angle={0.2} color="white" castShadow shadow-mapSize={[512, 512]} />
        <directionalLight position={[0, 5, -4]} intensity={4} />
        <directionalLight position={[0, -15, -0]} intensity={8} color="yellow" />
        <Physics gravity={[0, 0, 0]}>
          <Pointer />
          {inView && baubles.map((props, i) => (
            <Bauble key={i} {...props} decalTexture={decalTexture} decalPosition={decalPosition} decalRotation={decalRotation} decalScale={decalScale} decalMetalness={decalMetalness} decalRoughness={decalRoughness} />
          ))}
          <RoundButton 
            position={[4, -2.4, 0]} 
            rotation={[-0.1, 0, 0]} 
            normalSvgUrl="request.svg" 
            hoverSvgUrl="request1.svg" 
          />
          {/* <PresButton 
            position={[10, 4, 0]} 
            rotation={[0.2,-0.5, 0]} 
            normalSvgUrl="pres.svg" 
            hoverSvgUrl="pres.svg" 
          /> */}
        </Physics>
        <Environment files="/adamsbridge.hdr" />
        <EffectComposer disableNormalPass>
          <N8AO color="red" aoRadius={2} intensity={1} />
        </EffectComposer>
        {/* <Perf position="top-right" /> */}
      </Canvas>
    </div>
  );
};
