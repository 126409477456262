import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import ColaCasePage from './pages/ColaCasePage';
import FlintCasePage from './pages/FlintCasePage';
import BccCasePage from './pages/BccCasePage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Главная страница */}
        <Route path="/" element={<MainPage />} />
        
        {/* Страница Coca-Cola Case */}
        <Route path="/dodo-case" element={<ColaCasePage />} />
        <Route path="/flint-case" element={<FlintCasePage />} />
        <Route path="/bcc-case" element={<BccCasePage />} />

        {/* Другие маршруты могут быть добавлены здесь */}
      </Routes>
    </Router>
  );
}

export default App;
